.navbar-container-mobile {
    display: flex;
  }

.navbar-logo-mobile h1 {
  margin: 0;
  color: #38761D;
  padding: 24px 32px;
}

  aside {
    position: fixed;
    top: 0;
    right: 0;
    background-color: #6AA84F;
    height: 100vh;
    box-shadow: 0vw 1vw 2vw #888;
  }
  
  .menu-container-mobile {
    margin: 32vw 8vw;
    width: 20vw;
    height: 100vh;
  }

  .menu-container-mobile h2 {
    line-height: 2;
  }
  
  .nav-btn-container-mobile {
    position: fixed;
    right: 0;
    top: 0;
    width: 20vw;
    height: 100vh;
  }

  .nav-btn-container-mobile button {
    margin: 48px 32px 0;
    border: none;
    background: transparent;
    position: fixed;
    right: 0;
    top: 0;
  }
.design-page {
    background-attachment: fixed;
    background-size: 100%;
    overflow-y: auto;
    position: relative;
    padding: 12vw 0;
}

.design-wrapper {
  background-color: #FDFDFD;
  display: grid;
  place-items: center;
  margin: 200px 0;
  }

.design-intro {
  width: 68vw;
}

.design-title {
  position: relative;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #1f1f1f;
  justify-content: space-between;
  align-items: flex-end;
}

@media (max-width: 960px) {
  .design-title {
    flex-direction: column;
    align-items: first baseline;
  }
}

.design-title-left{
  width: 48vw;
}

.design-title-right {
  text-align: right;
}

@media (min-width: 961px) {
  .design-description {
    padding: 2vw 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .design-description-left {
    width: 22vw;
  }

  .design-description-right {
    width: 34vw;
  }
}

@media (max-width: 960px) {
  .design-description {
    padding: 2vw 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .design-description-left {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    padding: 2vw 0;
  }

  .design-description-left-item {
    display: flex;
    flex-direction: column;
  }
  
  .design-description-left-item h4,
  .design-description-left-item p {
    margin: 0;
  }

  .design-description-right {
    width: 100%;
  }
}

.design-context-wrapper {
  width: 100%;
  background-color: #f0f0f0;
  padding: 4vw 0;
}

.design-context {
  width: 72vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

@media (max-width: 960px) {
  .design-context {
    width: 100vw;
    flex-direction: column;
    justify-content: center;
  }

  .design-context-left img{
    padding: 8vw 0;
    width: 80vw;
  }

  .design-context-right {
    width: 80vw;
    padding-bottom: 8vw;
  }
  
}

@media (min-width: 961px) {
  .design-context-left img{
    width: 32vw;
  }

  .design-context-right {
    width: 32vw;
  }
  
}

.design-process {
  width: 72vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 4vw 0 6vw;
}

@media (max-width: 960px) {
  .design-process {
    width: 100vw;
    flex-direction: column;
    justify-content: center;
  }

  .design-process-left {
    padding: 8vw 0;
    width: 80vw;
  }

  .design-process-right img{
    width: 80vw;
    padding-bottom: 8vw;
  }
}

@media (min-width: 961px) {
  .design-process-left {
    width: 32vw;
  }
  
  .design-process-right img {
    width: 32vw;
  
  }
}

.design-features-wrapper {
  width: 100%;
  background-color: #f0f0f0;
}

.design-features {
  width: 72vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

@media (max-width: 960px) {
  .feature {
    display: flex;
    width: 100vw;
    justify-content: center;
    align-items: center;
  }

  .feature img {
    width: 80vw;
    padding: 12vw 0 4vw;
  }

  .design-even {
    flex-direction: column;
  }

  .design-odd {
    flex-direction: column;
  }

  .feature-details {
    width: 80vw;
  }
}

@media (min-width: 961px) {
  .feature {
    display: flex;
    width: 64vw;
    align-items: center;
    justify-content: space-between;
  }

  .feature img {
    width: 28vw;
    padding: 6vw 0;
  }

  .design-even {
    flex-direction: row;
  }
  
  .design-odd {
    flex-direction: row-reverse;
    text-align: right;
  }

  .feature-details {
    width: 28vw;
  }
}

.design-outro-wrapper {
  width: 100vw;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
}

.design-outro {
  width: 80vw;
  text-align: center;
}

.design-footer-container {
  background-color: #f0f0f0;
  position: absolute;
  bottom: 0;
}

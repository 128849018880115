.app-container {
  background-color: #fdfdfd;
  /* overflow-x: hidden; */
  min-height: 100vh;
}

body {
  margin: 0;
  padding: 0;
}

.navbar-container {
  top: 0px;
  z-index: 1000;
  position: sticky;
}

h1 {
  color: #1f1f1f;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 40px;
}

h2{
  color: #1f1f1f;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 24px;
}

h3{
  color: #1f1f1f;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 16px;
}

h4{
  color: #1f1f1f;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
}

p {
  color: #1f1f1f;
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6;
}

a {
  text-decoration: none;
}

.footer-container {
    width: 100vw;
}

.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    max-width: 64vw;
    margin: 0 auto;
}

@media (max-width: 960px) {
    .footer {
        flex-direction: column;
        text-align: center;
    }
  }
  

.footer-right {
    display: flex;
    flex-direction: row;
}

.footer-right p {
    margin: 20px;
    display: inline-block;
  }

.footer-right p:hover {
    cursor: pointer;
    color: #38761D;
}

.design-card-container {
    position: relative;
    width: 36vw;
    margin: 4vw 2vw;
}

.design-card-container:hover .design-card-image{
    box-shadow: .1vw .1vw 1vw #1f1f1f;
}

.design-card-container:hover .design-card-text h1{
    text-decoration: underline;
}

.design-card-image {
    width: 36vw;
    height: 18vw;
    overflow-y: hidden;
    overflow: hidden;
    
}

.design-card-image img {
    width: 100%;
}

.design-card-text-container {
    width: fit-content;
    height: auto;
}
.design-card-text {
    width: 36vw;
    height: auto;
  }

.design-card-text h5 {
    margin: 1vw 0;
}

.design-card-text h1 {
    margin: 2vw 0 1vw;
}

.design-card-text p {
    margin: 0;
}

@media (max-width: 480px) {
    .design-card-container {
    width: 80vw;
    margin: 12vw 2vw 6vw;
    }
    .design-card-image {
        width: 80vw;
        height: 40vw;
        overflow-y: hidden;
        overflow: hidden;
    }
    .design-card-image img {
        width: 100%;
    }
    
    .design-card-text-container {
        width: fit-content;
        height: auto;
    }
    
    .design-card-text {
        width: 80vw;
        height: auto;
      }
    
  }
.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 84vw;
  margin: 0 auto;
}


.navbar-item-left h2 {
  color: #38761D;
}

.navbar-item-right {
  display: flex;
  flex-direction: row;
}

.navbar-item {
  margin: 20px;
  display: inline-block;
}

.navbar-item:hover p {  
  cursor: pointer;
  color: #38761D;
}